<template>
  <div class="homeWrap">
    <div class="titleWrap">
      <img class="img" src="../../assets/homeLogo.png" alt="" />
    </div>
    <div class="contentWrap">
      <div class="topTitleWrap">
        <img class="img" src="../../assets/titleIcon.png" alt="" />
        <span class="title">鲜啤三十公里佘山酒厂</span>
      </div>
      <van-field
        readonly
        clickable
        name="picker"
        :value="prodDate"
        label="生产日期"
        placeholder="请选择生产日期"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-datetime-picker
          v-model="prodDates"
          :min-date="minDate"
          :max-date="maxDate"
          type="date"
          title="选择生产日期"
          @confirm="handleConfirm"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
    <div class="btmWrap">
      <van-button
        type="info"
        color="#FFE100"
        block
        size="normal"
        @click="handleSearch"
      >
        查询
      </van-button>
    </div>
  </div>
</template>

<script>
import { getBaseMaterialProductBatch } from "@/api/allRequest";
export default {
  data() {
    return {
      showPicker: false,
      prodDate: this.$moment(new Date()).format("YYYY-MM-DD"),
      prodDates: new Date(this.$moment(new Date()).format("YYYY-MM-DD")),
      minDate: new Date(
        this.$moment().subtract(3, "month").format("YYYY-MM-DD")
      ),
      maxDate: new Date(),
    };
  },
  methods: {
    //查询批次号
    findBaseMaterialProductBatch() {
      var data = {
        ...JSON.parse(localStorage.getItem("params")),
        prodDate: localStorage.getItem("prodDate"),
      };
      getBaseMaterialProductBatch(data).then((res) => {
        if (res.flag == 1) {
          localStorage.setItem("batchNo", res.data);
          this.$router.push({ path: "/tabPage" });
        } else {
          this.$toast.fail(res.message);
        }
      });
    },
    //点击确认时间
    handleConfirm(val) {
      this.prodDate = this.$moment(val).format("YYYY-MM-DD");
      this.prodDates = new Date(this.$moment(val).format("YYYY-MM-DD"));
      this.showPicker = false;
    },
    //点击查询
    handleSearch() {
      localStorage.setItem("prodDate", this.prodDate);
      this.findBaseMaterialProductBatch();
    },
  },
};
</script>
<style lang="scss" scoped>
.homeWrap {
  padding: 32px;
  height: 100vh;
  box-sizing: border-box;
  background: #ffe100;
  overflow: hidden;
  ::v-deep .van-cell {
    border-bottom: 1px solid #ebedf0;
    padding: 10px 0;
    .van-cell__title {
      font-size: 16px;
      color: #303133;
    }
    .van-cell__value {
      font-size: 16px;
    }
    &::after {
      display: none;
    }
  }
  .titleWrap {
    margin-top: 40px;
    margin-bottom: 32px;
    .img {
      width: 225px;
      height: 64px;
    }
  }
  .contentWrap {
    background: #fff;
    height: calc(100vh - 172px);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 32px 32px 0px 0px;
    box-sizing: border-box;
    padding: 32px;
    .topTitleWrap {
      display: flex;
      align-items: center;
      margin-bottom: 24px;
      .img {
        width: 24px;
        height: 24px;
      }
      .title {
        font-size: 20px;
        color: #303133;
        font-weight: bolder;
        margin-left: 8px;
      }
    }
  }
  .btmWrap {
    margin-top: 32px;
    margin-top: 190px;
    ::v-deep .van-button__text {
      color: #303133;
    }
  }
}
</style>
